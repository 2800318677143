import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import { Head } from "../../components/Head";
import Layout from "../../components/Layout";
import Section from "../../components/Section";
import { SharedHero } from "../../components/shared/SharedHero";

const TrasparenzaPage: React.FC = () => {
  return (
    <Layout>
      <Head title="Trasparenza" />

      <SharedHero />

      <Section bg={"utils.white"}>
        <Box>
          <Stack spacing={4} direction="column">
            <Heading
              as={"h1"}
              fontSize={["24px", "32px"]}
              fontWeight={"400"}
              textAlign={"left"}
              fontFamily={"body"}
              color={"accent.01"}
            >
              Trasparenza
            </Heading>
            <Text textAlign={"justify"}>
              Secondo la normativa in materia di trasparenza bancaria, (Decreto
              Legislativo n. 385/1993- Testo Unico Bancario e Trasparenza delle
              operazioni e dei servizi bancari e finanziari. Correttezza delle
              relazioni tra intermediari e clienti) HCBE mette a disposizione,
              periodicamente aggiornata, la documentazione relativa alla
              contrattualistica e alle informazioni pre-contrattuali, che qui di
              seguito è elencata e scaricabile cliccando sul singolo documento.
            </Text>

            <Accordion>
              <AccordionItem>
                <AccordionButton>
                  <Heading
                    flex={1}
                    textAlign="left"
                    as="h5"
                    size="md"
                    fontWeight="400"
                    p={2}
                    fontFamily={"body"}
                    _hover={{ color: "accent.01" }}
                  >
                    Prestiti
                  </Heading>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <UnorderedList pl={5}>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/prestiti/HCBE_CO_CONTRATTO_2021.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Contratto di prestito finalizzato
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/prestiti/HCBE_PF_SECCI_2021.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Informazioni europee di base sul credito ai consumatori
                        per il prestito finalizzato
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/prestiti/HCBE_PA_CONTRATTO_A_DISTANZA_2021.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Contratto di prestito finalizzato a distanza
                      </Link>
                    </ListItem>

                    <ListItem>
                      <Link
                        href="/documents/trasparenza/prestiti/HCBE_PA_SECCI_A_DISTANZA_2021.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Informazioni europee di base sul credito ai consumatori
                        per il prestito finalizzato a distanza
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/prestiti/HCBE_CO_CONTRATTO_PG_2021.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Contratto di prestito finalizzato - Partite IVA
                      </Link>
                    </ListItem>
                    {/* <ListItem>
                      <Link
                        href="/documents/trasparenza/prestiti/HCBE_Foglio Informativo PF PARTITE IVA 2021.v3.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Informazioni europee di base Partite IVA
                      </Link>
                    </ListItem> */}
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/prestiti/Scheda Prodotto Prestito Finalizzato.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Scheda prodotto prestito finalizzato
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/prestiti/Scheda Prodotto Prestito Finalizzato - partite IVA.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Scheda prodotto prestito finalizzato - Partite IVA
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/prestiti/HCBE_Foglio Informativo PF PARTITE IVA 2021.v3.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Foglio Informativo Prestiti Finalizzati Partite IVA
                      </Link>
                    </ListItem>
                  </UnorderedList>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Heading
                    flex={1}
                    textAlign="left"
                    as="h5"
                    size="md"
                    fontWeight="400"
                    p={2}
                    fontFamily={"body"}
                    _hover={{ color: "accent.01" }}
                  >
                    Leasing
                  </Heading>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <UnorderedList pl={5}>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/leasing/LE-CONTRATTO_HCBE.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Contratto di Leasing al consumo
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/leasing/LE-SECCI_PF_HCBE.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Informazioni europee di base sul credito ai consumatori
                        per il contratto di leasing al consumo
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/leasing/LE-CONTRATTO_PG_HCBE.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Contratto di Leasing a Partita IVA
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/leasing/LE_SECCI_PG_HCBE.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Informazioni europee di base Partie IVA
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/leasing/Foglio Informativo Leasing auto HCBE 10_21.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Foglio Informativo Leasing auto
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/leasing/Foglio Informativo Leasing auto opzione riscatto dlr HCBE 10_21.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Foglio Informativo Leasing auto opzione riscatto dealer
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/leasing/Scheda Prodotto Leasing al Consumo.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Scheda prodotto Leasing al Consumo
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/leasing/Scheda Prodotto Leasing a Partita IVA.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Scheda prodotto Leasing a Partita IVA
                      </Link>
                    </ListItem>
                  </UnorderedList>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Heading
                    flex={1}
                    textAlign="left"
                    as="h5"
                    size="md"
                    fontWeight="400"
                    p={2}
                    fontFamily={"body"}
                    _hover={{ color: "accent.01" }}
                  >
                    Rilevazione trimestrale dei tassi effettivi globali medi ai
                    fini della legge sull’usura
                  </Heading>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <UnorderedList pl={5}>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/rilevazione-trimestrale-legge-sull-usura/20240927_compliance e condotta_tassi_IV_trim 2024_HCBE_Italy.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Rilevazione trimestrale dei tassi effettivi globali medi
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/rilevazione-trimestrale-legge-sull-usura/Decreto-tassi-usura-ottobre-dicembre-2024-.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Decreto Ministero Tassi Soglia Usura
                      </Link>
                    </ListItem>
                  </UnorderedList>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Heading
                    flex={1}
                    textAlign="left"
                    as="h5"
                    size="md"
                    fontWeight="400"
                    p={2}
                    fontFamily={"body"}
                    _hover={{ color: "accent.01" }}
                  >
                    Normativa antiriciclaggio
                  </Heading>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <UnorderedList pl={5}>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/normativa-antiriciclaggio/CO-ANTIRICICLAGGIO_PF-20230515165032-ZZ1NLNUTWT.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Modulo KYC Persona Fisica in presenza
                      </Link>
                    </ListItem>

                    <ListItem>
                      <Link
                        href="/documents/trasparenza/normativa-antiriciclaggio/CO-ANTIRICICLAGGIO_PF_A_DISTANZA.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Modulo KYC Persona Fisica a distanza
                      </Link>
                    </ListItem>

                    <ListItem>
                      <Link
                        href="/documents/trasparenza/normativa-antiriciclaggio/CO-ANTIRICICLAGGIO_PG-20230515164501-UKBWMVCLYQ.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Modulo KYC Persona Giuridica in presenza
                      </Link>
                    </ListItem>
                  </UnorderedList>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Heading
                    flex={1}
                    textAlign="left"
                    as="h5"
                    size="md"
                    fontWeight="400"
                    p={2}
                    fontFamily={"body"}
                    _hover={{ color: "accent.01" }}
                  >
                    Guide pratiche
                  </Heading>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <UnorderedList pl={5}>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/guide/abf-in-parole-semplici.pdf"
                        color="accent.01"
                        isExternal
                      >
                        L'Arbitro bancario finanziario in parole semplici
                      </Link>
                    </ListItem>

                    <ListItem>
                      <Link
                        href="/documents/trasparenza/guide/Le-guide-della-Banca-d-Italia_Il-credito-ai-consumatori-in-parole-semplici.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Il credito ai consumatori in parole semplici
                      </Link>
                    </ListItem>

                    <ListItem>
                      <Link
                        href="/documents/trasparenza/guide/guida-centrale-rischi.pdf"
                        color="accent.01"
                        isExternal
                      >
                        La Centrale dei Rischi in parole Semplici
                      </Link>
                    </ListItem>

                    <ListItem>
                      <Link
                        href="/documents/trasparenza/guide/guide-BI-i-pagamenti-nel-commercio-elettronico_ITA.pdf"
                        color="accent.01"
                        isExternal
                      >
                        I pagamenti nel commercio elettronico in parole semplici
                      </Link>
                    </ListItem>

                    <ListItem>
                      <Link
                        href="/documents/trasparenza/guide/Vademecum-Furto identità 2022- Ver1142022-1.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Vademecum Furto di identità
                      </Link>
                    </ListItem>

                    <ListItem>
                      <Link
                        href="/documents/trasparenza/guide/Polizze_connesse_mutui_e_finanziamenti_versione_web.pdf"
                        color="accent.01"
                        isExternal
                      >
                        GUIDE IVASS: Le polizze connesse a mutui e finanziamenti
                      </Link>
                    </ListItem>
                  </UnorderedList>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Heading
                    flex={1}
                    textAlign="left"
                    as="h5"
                    size="md"
                    fontWeight="400"
                    p={2}
                    fontFamily={"body"}
                    _hover={{ color: "accent.01" }}
                  >
                    Assicurazioni e servizi
                  </Heading>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <UnorderedList pl={5}>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/assicurazioni-servizi/HCBE_SET INFORMATIVO_105 CL PI 01.12.2021.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Credit Life Lavoratori Dipendenti Privati CNP Santander
                        Insurance
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/assicurazioni-servizi/HCBE_SET INFORMATIVO_106 CL INA 01.12.2021.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Credit Life CNP Santander Insurance
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/assicurazioni-servizi/0110_28-29   Credit Life Recall.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Credit Life Recall
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/assicurazioni-servizi/HCBE_SET INFORMATIVO_107 LEASING PROTECTION 01.12.2021.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Credit Life Leasing Protection CNP Santander Insurance
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/assicurazioni-servizi/HCBE_SET INFORMATIVO_108 CL SENIOR 01.12.2021.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Credit Life Senior CNP Santander Insurance
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/assicurazioni-servizi/HCBE_SET INFORMATIVO_GOLDEN BONUS_ 0203-33_pos_01.08.22.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Golden Bonus CNP Santander Insurance
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/assicurazioni-servizi/HCBE_GOLDEN_BONUS_Set Informativo_RECALL_01.08.22.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Golden Bonus Recall
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/assicurazioni-servizi/Set Informativo_ GOLDEN GAP_ 0204-29_Recall_01.01.22.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Golden GAP Recall
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/assicurazioni-servizi/Hyundai Tyres Leasing_SET.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Tyres Leasing Nobis
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/assicurazioni-servizi/HCBE_Protezione_Auto_Ed_10_2022.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Motor Insurance Coveà
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/assicurazioni-servizi/NEW_PACK_MINI_PRODOTTI_ED_02_2022.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Mini Pack Coveà
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/assicurazioni-servizi/Set Stop&Mark Hyundai Capital Bank.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Stop&Mark Europe Assistance
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/assicurazioni-servizi/HCBE New Smart Coverages_SET_post CM Opinion.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Smart Coverages Nobis
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/assicurazioni-servizi/allegato-3-4-ter.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Allegato 3, 4-ter
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/assicurazioni-servizi/Guida Sereno HCBE.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Incendio e furto Nobis
                      </Link>
                    </ListItem>
                  </UnorderedList>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Heading
                    flex={1}
                    textAlign="left"
                    as="h5"
                    size="md"
                    fontWeight="400"
                    p={2}
                    fontFamily={"body"}
                    _hover={{ color: "accent.01" }}
                  >
                    Informativa sulla vendita a distanza di servizi finanziari
                  </Heading>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <UnorderedList pl={5}>
                    <ListItem>
                      <Link
                        href="/documents/trasparenza/informativa-vendita-distanza/informativa-vendita-distanza.pdf"
                        color="accent.01"
                        isExternal
                      >
                        Informativa sulla vendita a distanza di servizi
                        finanziari
                      </Link>
                    </ListItem>
                  </UnorderedList>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Stack>
        </Box>
      </Section>
    </Layout>
  );
};

export default TrasparenzaPage;
